import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './css/bulma.css';
import './css/profile_timeline.scss';
import './css/profile.scss';
import Home from './home/Index';
import Resume from './resume/Index';

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route path="/resume" component={Resume} />
    </BrowserRouter>
  )
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import ExtLink from './../resume/components/Link';
import Header from './components/Header';

const Index = () => {
  return (
    <>
      <div className="content container-home">
        <Header />
        <p>
          My name is Marvin Baltazar, and my friends call me <span className="has-text-weight-semibold">Marvs</span>.
        </p>
        <p>
          An Electronics Engineer turned Software Engineer, I specialize in turning ideas into web applications. That involves designing, launching, growing, and maintaining products that people use.
        </p>
        <p>
          I currently work as a Senior Ruby Engineer at Empire Flippers. Prior to that I was the first engineer and Development Lead of LaunchPad Recruits, where I worked for 9 years before the company was acquired.
        </p>
        <p>
          You can find the details of my professional experience <Link to='/resume'>here</Link>.
        </p>
        <p>
          I also regularly write articles at my Technology and Financial Independence blog in <ExtLink href="https://techandfi.com">techandfi.com</ExtLink>. These are mostly on the topics of personal development, software development, and personal finance.
        </p>
      </div>
    </>
  )
}

export default Index;

import Link from './Link';

const OtherProjects = () => {
  return (
      <div className="container">
        <div className='content'>
          <p className='is-size-4'>Other Projects</p>
          <h4>
            <Link href='https://github.com/marvs'>https://github.com/marvs</Link>
          </h4>
          <p>
            <ul>
              <li><strong>navfund</strong> - Ruby gem for fetching the current values of Philippine mutual funds</li>
              <li><strong>psei</strong> - Ruby gem that returns the Philippine Stock Exchange end-of-day data</li>
            </ul>
          </p>
          <h4>
            <Link href='https://techandfi.com'>https://techandfi.com</Link>
          </h4>
          <p>
            My blog where I write articles regularly, with emphasis on topics about Web Development and Personal Development.
          </p>
        </div>
      </div>
  )
}

export default OtherProjects;

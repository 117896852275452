const Summary = () => {
  const currentYear = new Date().getFullYear();
  const leadYears = currentYear - 2010;

  return (
    <section className="summary">
      <div className="container">
        <p>
          Solid yet versatile Engineer with more than {leadYears} years experience in developing and leading web application projects. I have built and managed systems from an MVP to a full-featured product with millions of users. Self-motivated and highly adept at remote work.
        </p>
      </div>
    </section>
  )
}

export default Summary;

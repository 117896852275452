const Technologies = () => {
  return (
    <div class='content'>
      <hr/>
      <p className='is-size-4'>Technologies</p>
      <ul>
        <li>Ruby, Rails, JavaScript, ReactJS, Python</li>
        <li>MySQL, PostgreSQL</li>
        <li>Docker, AWS</li>
      </ul>
    </div>
  )
}

export default Technologies;

const Skills = () => {
  const currentYear = new Date().getFullYear();
  const techYears = currentYear - 2005;
  const leadYears = currentYear - 2010;

  return (
    <div class='content'>
      <hr/>
      <p className='is-size-4'>Core Competencies</p>
      <ul>
        <li>{techYears} years in the technology sector</li>
        <li>{leadYears} years technical leadership experience</li>
        <li>As the first engineer of a SaaS startup, became an integral part of the company lifecycle from MVP, growth, up to exit/acquisition</li>
        <li>Extensive experience in web application development</li>
      </ul>
    </div>
  )
}

export default Skills;
